<template>
  <div>

    <v-dialog content-class="pdf-adobe-viewer-dialog" v-model="open" max-width="1100px" :scrollable="!false" persistent @keydown="onKeyDown">
    <v-card dense style="height:100%;">
        <div flat dense class="viewer-commands">
            <div class="close-button-area">
                <v-btn icon class="ml-4 text-bold mt-1x d-inline-block" @click="() => open=false">
                    <Icon class="text-bold" color="#000"  type="close"></Icon>
                </v-btn>
            </div>

        </div>                        

        <v-card-text style="height:100%;" dense class="mxt-4 pa-0">
            <PdfViewer :id="id" ref="pdfviewer"/>
        </v-card-text>
    </v-card>
  </v-dialog>

  </div>
</template>

<script setup>
    // Usage:  
    // import DialogPdfAdobe from '@shared/features/pdf/DialogPdfAdobe'
    // eventbus.dialog.pdfvieweradobe({ raw: model.downloadExample(), "Urenstaat.pdf" })

    import PdfViewer from '@shared/features/pdf/ViewerAdobe'
    import eventbus from '@app/eventbus'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import {ref, onMounted, computed} from 'vue'
    import Icon from '@controls/icons/Icon'
    const props = defineProps({
        id: {
            type: [String, Number],
            default: "dlg-adobe-dc-view"
        }
    });    

    var open = ref(false);
    var title = ref("PDF Weergave");

    let pdfviewer = ref(null);         
    
    eventbus.dialog.pdf.on( (params, fileName, options) => {
        params = params ||{}
        open.value = true;
        title.value = fileName || 'PDF Weergave';
        options = options || {};
        window.edpvr = pdfviewer
        console.log(pdfviewer.value)
        var fn = null;
        if (params.base64) {
            fn = ()=>pdfviewer.value.api.loadBase64(params.base64, fileName, options)

        } else if (params.raw) {
            fn = ()=>pdfviewer.value.api.loadRaw(params.raw, fileName, options)

        } else if (params.url) {
            fn = ()=>pdfviewer.value.api.loadBase64(params.url, fileName, options)

        } else if (params.file) {
            fn = ()=>pdfviewer.value.api.loadLocalFile(params.file, fileName, options)

        } else {
            throw "When Opening pdf viewer, either base64 data, raw data or an url must be provided.";
        }
        setTimeout(fn, 100);
    })

    function onKeyDown(evt, b, c) {
        if (evt && evt.key == "Escape") {
            open.value = false;
        }
    }

</script>
