<template>
    <div>        
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="project_limit.open" @click="()=>onOpen(item)"></ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem action="project.financials" icon="purchase" @click="()=>onShowInvoices(item)">Toon inkoopfacturen</ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem action="project_limit.remove" @click="dt.onStartRemove(item)"></ActionMenuItem>
            </template>

            <template v-slot:footer.prepend>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-card-text>
                                <ActionButton action="project_limit.create" sub dusk="btn-add-limit" :model="model" icon="add" xmain xprimary @click='addLine'>Nieuwe Kostenlimiet</ActionButton>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

        <template v-slot:item.open_graph="{item}">
            <v-progress-linear style="min-width:250px;"
                :value="pctInvoiced(item)" 
                :color="pctInvoicedColor(item)"
                height="8"
                >
                <template v-slot:default="{ value }">
                    <span style="position:fixed;background-color:#fffa">{{ Math.ceil(value) }}%</span>
                </template>
            </v-progress-linear>
        </template>

        </Datatable>

    </div>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { project_costlimit as api } from '@app/api'
    import dlg from '@app/dlg'
    import ActionButton from '@controls/buttons/ActionButton'

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    var headers = [
        { type: 'cmd' },

        { text: "Relatie",       value: "rel_name", type:'open'},
        { footer: 'amount', text: "Limiet",        value: "pl_amount", type:'amount'},
        { footer: 'amount', text: "Gefactureerd",  value: "invoiced_amount", type:'amount'},
        { footer: 'amount', text: "Openstaand",    value: "open_amount", type: 'amount'},
        { text: "",              value: "open_graph", },
        { isUtc: true, text: "Gewijzigd",                value: "updated_at",   type: 'datetime', visible: false},
        { text: "Gewijzigd door",           value: "updated_by",    visible: false},
        { isUtc: true, text: "Aangemaakt",               value: "created_at",    type: 'datetime', visible: false, sort: 'desc'},
        { text: "Aangemaakt door",          value: "created_by",    visible: false},
    ]

    var customSaveHandler = (_dt, modelName, params) => { 
        if (modelName == "project_limit") {
            _dt.search(); // refresh
        }
        if (modelName == "purchaseinvoice") {
            if (!_dt.items.find( (item) => item.id_relation == params.id_relation)) {
                return;
            }
            _dt.search(); // refresh
        } 
    }

    var dt = useDataTable("dt_project_cost_limits", "project_limit", api, headers, {
        // hideDefaultFooter: true,
        noPager: true,
        showFootSlots: true,
        itemName: {
            prefix: 'De',
            single: 'kostenlimiet',
            plural: 'kostenlimieten',
        }, parent: {
            id_project: props.model.id
        },
        preventRemoveHandler: ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        preventSaveHandler:   ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        customSaveHandler:    customSaveHandler,
        canArchive: false
    })

    function addLine() {
        dlg.create('project_limit', {id_project: props.model.id})
    }
    async function onOpen(item) {
        await dlg.promised.open('project_limit', item)
    }

    function onShowInvoices(item) {
        var id_project = props.model.id;
        var id_relation = item?.id_relation;        
        dlg.promised.open('datalist_purchaseinvoice', {filter: {id_project: id_project, id_relation: id_relation, selectfor: 'project'}})       
    }
    function pctInvoiced(item) {
        var limit    = Number(item.pl_amount || 0);
        var invoiced = Number(item.invoiced_amount || 0);
        if (!invoiced || !limit) {
            return 0;
        }
        var pct = Math.round( (invoiced / limit) * 100);

        return pct;
    }
    function pctInvoicedColor(item) {
        var pct = pctInvoiced(item);
        if (pct >100) {
            return "error";
        }
//        if (pct >= 80) {
//            return "warning";
//        }
        return "success";
    }

</script>