import clsSettings2 from '@cls/clsSettings2'

var modelName="portal"
var arrFields = ['logo_data'];
class clsPortalSettings extends clsSettings2 {

    logo_data = null

    constructor() {
        super(modelName, arrFields);
    }

}

export default clsPortalSettings;
