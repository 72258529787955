<template>
    <div>
        <DialogNote/>
        <DialogAttachments/>
        <DialogAttachmentUpload/>
        <DialogSelectProject/>
        <DialogSelectProduct/>
        <DialogSelectStandardText/>
        <DialogSelectTender/>
        <DialogConfigureTable/>
        <DialogRelation/>
        <DialogRelationAddress/>
        <DialogRelationContact/>
        <DialogSalesInvoiceSend/>
        <DialogSalesInvoiceReminder/>
        <DialogSalesInvoice/>
        <DialogSalesInvoiceLog/>
        <DialogPaymentOrder/>
        <DialogSupplier/>
        <DialogCustomField/>
        <DialogKVK/>
        <DialogSupport/>
        <DialogStandardText/>
        <DialogCosttype/>
        <DialogHourType/>
        <DialogProduct/>
        <DialogRecipe/>
        <DialogProductVendorSelect/>
        <DialogWorkorder/>
        <DialogProductGroup/>
        <DialogDepartment/>
        <DialogJobtitle/>
        <DialogCreditrestriction/>
        <DialogDispute/>
        <DialogPurchaseInvoiceLog/>
        <DialogPurchaseInvoice/>
        <DialogTender/>
        <DialogTenderCopy/>
        <DialogTenderReplace/>
        <DialogTenderAccept/>
        <DialogTenderReject/>
        <DialogTenderVersions/>
        <DialogTenderLog/>
        <DialogTenderFinish/>
        <DialogTenderCreateInvoice/>
        <DialogProjectGroup/>

        <DialogProject/>
        <DialogExtraWork/>
        <DialogOtherCost/>
        <DialogCostLimit/>

        <DialogSalesOrder/>
        <DialogSalesOrderTerm/>
        <DialogCreateSalesOrderTerm/>
        <DialogHourList/>

        <DialogManday/>

        <DialogVersionUpdate />
        <DialogHelp/>

        <DialogPurchaseInvoiceData/>

    </div>
</template>

<script setup>

//
// Dialogs which are used 'project wide'. E.g. which are not accessible in one listview only.
//

import DialogNote from '@/ui/dialogs/DialogNote'
import DialogKVK from '@/ui/dialogs/select/DialogKVK'
import DialogSupport from '@/ui/dialogs/DialogSupport'
import DialogAttachments from '@/ui/dialogs/DialogAttachments'
import DialogAttachmentUpload from '@/ui/dialogs/DialogAttachmentUpload'
import DialogSelectProject from '@/ui/dialogs/select/DialogSelectProject'
import DialogSelectProduct from '@/ui/dialogs/select/DialogSelectProduct'
import DialogSelectStandardText from '@/ui/dialogs/select/DialogSelectStandardText'
import DialogSelectTender from '@/ui/dialogs/select/DialogSelectTender'
import DialogConfigureTable from '@shared/ui/dialogs/DialogConfigureTable'    

import DialogRelation from '@/ui/dialogs/relation/DialogRelation'    
import DialogRelationAddress from '@/ui/dialogs/relation/DialogRelationAddress'    
import DialogRelationContact from '@/ui/dialogs/relation/DialogRelationContact'    

import DialogSalesInvoiceSend from '@/ui/dialogs/salesinvoice/DialogSalesInvoiceSend.vue'  
import DialogSalesInvoiceReminder from '@/ui/dialogs/salesinvoice/DialogSalesInvoiceReminder.vue'  
import DialogSalesInvoiceLog from '@/ui/dialogs/salesinvoice/DialogSalesInvoiceLog.vue'  

import DialogPaymentOrder from '@/ui/dialogs/DialogPaymentOrder.vue'    
import DialogSalesInvoice from '@/ui/dialogs/salesinvoice/DialogSalesInvoice.vue'    

import DialogSupplier from '@/ui/dialogs/product/DialogProductSupplier.vue'    
import DialogCustomField from '@/features/customfields/dialog/DialogCustomField.vue'    
import DialogStandardText from '@/ui/dialogs/DialogStandardText.vue'    
import DialogProduct from '@/ui/dialogs/product/DialogProduct.vue'    
import DialogRecipe from '@/ui/dialogs/product/DialogRecipe.vue'    
import DialogProductGroup from '@/ui/dialogs/product/DialogProductGroup.vue'    
import DialogProductVendorSelect from '@/ui/dialogs/product/DialogProductVendorSelect.vue'    
import DialogWorkorder from '@/ui/dialogs/workorder/DialogWorkorder'

import DialogDepartment from '@/ui/dialogs/DialogDepartment'    
import DialogJobtitle from '@/ui/dialogs/DialogJobtitle'    
import DialogCosttype from '@/ui/dialogs/DialogCosttype'    
import DialogHourType from '@/ui/dialogs/hours/DialogHourType'    

import DialogCreditrestriction from '@/ui/dialogs/DialogCreditrestriction'    
import DialogDispute from '@/ui/dialogs/DialogDispute'    

import DialogPurchaseInvoice from '@/ui/dialogs/purchaseinvoice/DialogPurchaseInvoice.vue'    
import DialogPurchaseInvoiceLog from '@/ui/dialogs/purchaseinvoice/DialogPurchaseInvoiceLog.vue'  
import DialogTender from '@/ui/dialogs/tender/DialogTender.vue'    
import DialogTenderCopy from '@/ui/dialogs/tender/DialogTenderCopy.vue'    
import DialogTenderReplace from '@/ui/dialogs/tender/DialogTenderReplace.vue'    
import DialogTenderVersions from '@/ui/dialogs/tender/DialogTenderVersions.vue'    
import DialogTenderLog from '@/ui/dialogs/tender/DialogTenderLog.vue'    
import DialogTenderAccept from '@/ui/dialogs/tender/DialogTenderAccept.vue'    
import DialogTenderReject from '@/ui/dialogs/tender/DialogTenderReject.vue'    
import DialogTenderFinish from '@/ui/dialogs/tender/DialogTenderFinish.vue'    
import DialogTenderCreateInvoice from '@/ui/dialogs/tender/DialogTenderCreateInvoice.vue'    
import DialogProjectGroup from '@/ui/dialogs/project/DialogProjectGroup.vue'    

import DialogProject from '@/ui/dialogs/project/DialogProject.vue'    
import DialogExtraWork from '@/ui/dialogs/project/DialogExtraWork.vue'    
import DialogOtherCost from '@/ui/dialogs/project/DialogOtherCost.vue'    
import DialogCostLimit from '@/ui/dialogs/project/DialogCostLimit.vue'    
    
import DialogSalesOrder from '@/ui/dialogs/salesorder/DialogSalesOrder.vue'    
import DialogSalesOrderTerm from '@/ui/dialogs/salesorder/DialogSalesOrderTerm.vue'    
import DialogCreateSalesOrderTerm from '@/ui/dialogs/salesorder/DialogCreateSalesOrderTerm.vue'    

import DialogHourList from '@/ui/dialogs/hours/DialogHourList.vue'    
import DialogVersionUpdate from '@/ui/dialogs/DialogVersionUpdate'
import DialogHelp from '@/ui/dialogs/DialogHelp'
import DialogManday from '@/ui/dialogs/manday/DialogManday.vue'    
import DialogPurchaseInvoiceData from '@/ui/dialogs/purchaseinvoice/DialogPurchaseInvoiceData.vue'    


// Make non-modal dialogs movable
(function () { // make vuetify dialogs movable
    const d = {};
    document.addEventListener("mousedown", e => {
        const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
        if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-card__title")) { // element which can be used to move element
            d.el = closestDialog; // element which should be moved
            d.mouseStartX = e.clientX;
            d.mouseStartY = e.clientY;
            d.elStartX = d.el.getBoundingClientRect().left;
            d.elStartY = d.el.getBoundingClientRect().top;
            d.el.style.position = "fixed";
            d.el.style.margin = 0;
            d.oldTransition = d.el.style.transition;
            d.el.style.transition = "none"
        }
    });
    document.addEventListener("mousemove", e => {
        if (d.el === undefined) return;
        // The code below allows the dialogs to move 75% over the boundaries of the screen (except top).
        // To keep the dialogs within the screen, use: 
        //            d.el.style.left = Math.min(
        //                Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
        //                window.innerWidth - d.el.getBoundingClientRect().width
        //            ) + "px";
        //            d.el.style.top = Math.min(
        //                Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
        //                window.innerHeight - d.el.getBoundingClientRect().height
        //            ) + "px";
        d.el.style.left = Math.min(
            Math.max(d.elStartX + e.clientX - d.mouseStartX, -(d.el.getBoundingClientRect().width*0.75)),
            window.innerWidth - (d.el.getBoundingClientRect().width/4)
        ) + "px";
        d.el.style.top = Math.min(
            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
            window.innerHeight - (d.el.getBoundingClientRect().height/4)
            ) + "px";
    });
    document.addEventListener("mouseup", () => {
        if (d.el === undefined) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined
    });
    // setInterval(() => { // prevent out of bounds
    //     const dialog = document.querySelector(".v-dialog.v-dialog--active");
    //     if (dialog === null) return;
    //     dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
    //     dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
    // }, 100);
})();

</script>
