//
// The content of this file is generated with  a SQL Script
//
//   Generate this list from the database: 
// 
/*
select 'let customfields = [' 
 union select concat ('{ id: ', rpad(concat(id, ','), 6, ' '), 'name: ', rpad(replace(concat('''', name, ''','), ' ', ''), 20, ' '), 'type: ',  concat('''', type, ''''), ' },') 
 	 from field_types 
 union select '];'
 union select 'let customfieldtypes = {' 
 union select concat (rpad(replace(type, ' ', ''), 36, ' '), ': ', rpad(concat(id, ','), 6, ' '), ' // --- ', ifnull(name, '')) 
 	 from field_types 
 union select '};'
 union select 'export {customfieldtypes, customfields} ;'

*/
// 

let customfields = [
    { id: 10,   name: 'Checkbox',         type: 'checkbox' },
    { id: 20,   name: 'Tekst',       type: 'text' },
    { id: 25,   name: 'Tekstblok',        type: 'longtext' },
    { id: 30,   name: 'Combobox',         type: 'combo' },
    { id: 40,   name: 'Bedrag',           type: 'amount' },
    { id: 50,   name: 'Hoeveelheid',      type: 'number' },
    { id: 60,   name: 'Percentage',       type: 'percentage' },
    { id: 70,   name: 'Decimaalgetal',    type: 'decimal' },
    { id: 80,   name: 'Datum',            type: 'date' },
    { id: 90,   name: 'Tijd',             type: 'time' },
];
let customfieldtypes = {
    checkbox                            : 10,    // --- Checkbox
    text                                : 20,    // --- Tekstregel
    longtext                            : 25,    // --- Tekstblok
    combo                               : 30,    // --- Combobox
    amount                              : 40,    // --- Bedrag
    number                              : 50,    // --- Hoeveelheid
    percentage                          : 60,    // --- Percentage
    decimal                             : 70,    // --- Decimaal getal
    date                                : 80,    // --- Datum
    time                                : 90,    // --- Tijd
};
export {customfieldtypes, customfields} ;