import { render, staticRenderFns } from "./AppDialogs.vue?vue&type=template&id=6396acfe"
import script from "./AppDialogs.vue?vue&type=script&setup=true&lang=js"
export * from "./AppDialogs.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports