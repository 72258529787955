<template>    
    <v-row dense>
        <v-col :sm="compColLeft.sm" :md="compColLeft.md" :lg="compColLeft.lg" :xl="compColLeft.xl"  :class="compClassColLeft">
            <slot name="left">
                <div class="v-top">
                <label :class="clsLabel">{{label}}</label>
                <caption class="caption-sub" v-if="subLabel">{{subLabel}}</caption>            
                </div>
            </slot>
        </v-col>
        <slot name="right">
            <v-col class="position-relative" :sm="compColRight.sm" :md="compColRight.md" :lg="compColRight.lg" :xl="compColRight.xl" :class="compClassColRight">
                <slot></slot>
                <span class="text-small text-italic"><slot name="info"></slot></span>
                <span v-if="tip" style="position:absolute;" :style="{right:tipRight,top:tipTop}">
                    <TipMenu>
                        <slot name="tip"></slot>
                    </TipMenu>
                </span>
            </v-col>
        </slot>
    </v-row>
</template>

<script setup>
import {computed} from 'vue'
import TipMenu from "@controls/menu/TipMenu.vue";

//
// A simplification for the FormRow control. 
// Usage: 
//
//   import Row from '@controls/forms/Row'
//    const colL={sm:4,lg:2}
//    const colR={sm:4,lg:4}    
//
//   <Row :l="colL" :r="colR" label="Naam">
//       <TextField max-length="255" :model="model" v-model="model.nameremark"></TextField>
//   </Row>
//
//   <Row :l="colL" :r="colR">
//       <template v-slot:left>
//          <TextField max-length="255" :model="model" v-model="model.surename"></TextField>
//       </template
//       <TextField max-length="255" :model="model" v-model="model.lastname"></TextField>
//   </Row>

const props = defineProps({
        label: {
            type: [String],
            default: ""
        },
        subLabel: {
            type: [String],
            default: ""
        },
        l: {
            type: [Object],
        },
        r: {
            type: [Object],
        },
        classLeft: {
            type: [String],
            default: null
        },
        classRight: {
            type: [String],
            default: null
        },
        // For text area, the label might be top aligned.
        labelVTop: {
            type: [Boolean],
            default: false
        },
        classLabel: {
            type: [String],
            default: null
        },
        even: {
            type: [Boolean],
            default: false
        },
        gutter: {
            type: [Object],
        },
        tip: {
            type: [Boolean],
            default: false
        },
        tipRight: {
            type: [String],
            default: "12px"
        },
        tipTop: {
            type: [String],
            default: "9px"
        },


    });
    const compColLeft = computed({
        get() {
            var cl = props.even ? {sm:6} : ( props.l || {sm:4});
//            cl = props.l || {sm:4};
            cl.xl=cl.xl||cl.lg||cl.md||cl.md;
            cl.lg=cl.lg||cl.md||cl.md;
            cl.md=cl.md||cl.md;
            return cl
        }
    })
    const compColRight = computed({
        get() {
            var cr = props.even ? {sm:6} : ( props.r || {});   ; // by default undefined.
//            cr = props.r || {};
            cr.xl=cr.xl||cr.lg||cr.md||cr.md;
            cr.lg=cr.lg||cr.md||cr.md;
            cr.md=cr.md||cr.md;
            return cr
        }
    })

    const compClassColLeft = computed({
        get() {
            var cls = props.classLeft || "";
            var clsGutter = "";
            if (props.gutter) {
                if (undefined !== props.gutter.xl) {
                    clsGutter = `${clsGutter} pr-xl-${props.gutter.xl}`
                }
                if (undefined !== props.gutter.lg) {
                    clsGutter = `${clsGutter} pr-lg-${props.gutter.lg}`
                }
                if (undefined !== props.gutter.md) {
                    clsGutter = `${clsGutter} pr-md-${props.gutter.md}`
                }
                if (undefined !== props.gutter.sm) {
                    clsGutter = `${clsGutter} pr-${props.gutter.sm}`
                }
            }
            if (!cls && !clsGutter) {
                return null;
            }
            return `${cls} ${clsGutter}`;
        }
    })
    const compClassColRight = computed({
        get() {
            var cls = props.classRight || "";
            var clsGutter = "";
            if (props.gutter) {
                if (undefined !== props.gutter.xl) {
                    clsGutter = `${clsGutter} pl-xl-${props.gutter.xl}`
                }
                if (undefined !== props.gutter.lg) {
                    clsGutter = `${clsGutter} pl-lg-${props.gutter.lg}`
                }
                if (undefined !== props.gutter.md) {
                    clsGutter = `${clsGutter} pl-md-${props.gutter.md}`
                }
                if (undefined !== props.gutter.sm) {
                    clsGutter = `${clsGutter} pl-${props.gutter.sm}`
                }
            }
            if (!cls && !clsGutter) {
                return null;
            }
            return `${cls} ${clsGutter}`;
        }
    })

    const clsLabel = computed({
        get() {
            var clsSubLabel = props.subLabel?'label-top':'';
            var clsVTop = props.labelVTop?'v-top':'';
            return `${this.classLabel||''} ${clsSubLabel} ${clsVTop}`; 
        }
    })

</script>