import { Mark } from '@tiptap/core';

var utils = {
    selectWord: (editor, commands) => {
            const { state, dispatch } = editor.view;
            const { $from, $to } = state.selection;
            let start = $from.pos;
            let end = $to.pos;
            
            // When no selection is made, use the current word.
            if (start == end) {
                while (start > 0) {
                        var txt = state.doc.textBetween(start - 1, start, ' ');
                        if (!txt || txt === ' ') {
                        break;
                        }
                        start--;
                }
                while (end < state.doc.content.size) {
                    var txt = state.doc.textBetween(end, end + 1, ' '); 
                    if (!txt || txt === ' ') {
                        break;
                    }
                    end++;
                }

            }
            commands.setTextSelection({from:start, to: end})
        }
    }

export default utils;