import { Mark } from '@tiptap/core';
import utils from './tiptap-utils';

// Allow for a custom element <w10> in the markup. 
// W10 stands for width 10%.
const Highlight = Mark.create({
    name: 'wx',
    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    parseHTML() {
        return [
            {
                tag: 'wx',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['wx', HTMLAttributes /* core.mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)*/, 0];
    },
    addCommands() {
        return {
            toggleWX: () => ({ editor, commands }) => {
                utils.selectWord(editor,commands);
                window.wxc = commands; window.wxe = editor;
                var fnHasMark = (m) =>{
                    const {from, to} = editor.state.selection;
                    const hasMark = editor.state.doc.rangeHasMark(from, to, m);
                    return hasMark;
                }

                if (fnHasMark(editor.schema.marks.w20 )) {
                    commands.unsetMark("w20")
                    commands.setMark("w30")

                } else if (fnHasMark(editor.schema.marks.w30)) {
                    commands.unsetMark("w30")
                    commands.setMark("w40")

                } else if (fnHasMark(editor.schema.marks.w40)) {
                    commands.unsetMark("w40")
                    commands.setMark("w50")

                } else if (fnHasMark(editor.schema.marks.w50)) {
                    commands.unsetMark("w50")
                
                } else {
                    commands.setMark("w20")
                }
                // return commands.toggleMark(this.name)
            },
        }
    },
    addKeyboardShortcuts() {
        return {
            'Mod-l': () => {
                this.editor.commands.toggleWX()
                return true;
            }
        }
      
    },
    addInputRules() {
        return [
        ];
    },
    addPasteRules() {
        return [];
    },
});


export { Highlight, Highlight as default };