import { Mark } from '@tiptap/core';
import utils from './tiptap-utils';

// Allow for a custom element <w30> in the markup. 
// W30 stands for width 30%.
const Highlight = Mark.create({
    name: 'w30',
    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    parseHTML() {
        return [
            {
                tag: 'w30',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['w30', HTMLAttributes /* core.mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)*/, 0];
    },
    addCommands() {
        return {
            setW30: () => ({ editor, commands }) => {
                utils.selectWord(editor,commands);
                commands.unsetMark("w25")
                return commands.setMark(this.name)
            },
            toggleW30: () => ({ editor, commands }) => {
                console.log('toggleW30')
                utils.selectWord(editor,commands);
                commands.unsetMark("w25")
                return commands.toggleMark(this.name)
            },
            unsetW30: () => ({ editor, commands }) => {
                utils.selectWord(editor,commands);
                return commands.unsetMark(this.name)
            },
        }
    },
    addKeyboardShortcuts() {
        return {};
    },
    addInputRules() {
        return [
        ];
    },
    addPasteRules() {
        return [];
    },
});


export { Highlight, Highlight as default };