<template>
    <v-dialog class="action-dialog action-dialog-small dialog-data-list" 
        :key="dialog.dlgKey"
        :content-class="dialog.cls" 
        persistent 
        scrollable
        v-model="dialog.open" 
        :maxWidth="dialog.maxWidth" 
        @keydown.stop="(evt) => dialog.onKeyDown(evt)">
            <v-card flat :loading="dialog.isLoading">

                <v-card-title flat class="pt-0 pb-0 px-4">
                    <slot name="header-title">
                        <v-list class="dialog-header-title">
                            <v-list-item class="pa-0">
                                <v-list-item-action class="icon-container ma-0 pa-2 mr-4" v-if="dialog.icon" >
                                    <slot name="header-icon">
                                        <Icon large color="primary" type="bullet-list"></Icon>
                                    </slot>
                                </v-list-item-action>                                            
                                <v-list-item-content>
                                    <v-list-item-title >
                                        <slot name="header-maintitle">
                                            <h1 class="capitalize"><slot name="title">{{dialog.title}}</slot></h1>
                                        </slot>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>                                    
                        </v-list>
                    </slot>
                        
                        <v-spacer></v-spacer> 

                        <div class="commands">
                            <slot name="header-pre-actions"></slot>
                            <slot name="header-close">
                                <HeaderButton dusk="btn-hdr-close" no-count class="ml-2" type="close" :disabled="dialog.isLoading" @click="dialog.onClose()"/>
                            </slot>
                            <slot name="header-post-actions"></slot>

                        </div>

                </v-card-title>                           

                <v-card-text flat class="py-0 px-0">
                    <v-divider class="xmb-4"></v-divider>
                </v-card-text>
                
                <v-card-text class="overflow-y-visible pb-0 px-4" v-if="useFilter">
                    <slot name="filter-section" >
                        <v-form @submit.prevent="()=>dt.search()" autocomplete="off" >
                            <v-row dense class="">
                                <v-col>
                                    <slot name="filter"></slot>
                                </v-col>
                                <v-col class="col-auto">
                                    <ActionButton dusk="btn-search" submit class="ml-0" main primary>
                                        <slot name="searchtext">Zoek</slot>
                                    </ActionButton>
                                    <ActionMenu dusk="btn-menu" v-if="cmdMenu">
                                        <ActionButton class="ml-2" comboContextButton icon='menu-vertical'></ActionButton>
                                        <template v-slot:dropdown>
                                            <slot name="cmd-menu">
                                            </slot>
                                        </template>
                                    </ActionMenu>
                                </v-col>                                
                            </v-row>
                        </v-form>
                    </slot>
                </v-card-text>

                <v-divider v-if="useFilter" class="mt-4"></v-divider>

                <v-card-text class="py-0 px-0">
                    <slot name="before-datatable"></slot>

                        <Datatable :dt="dt" class="datatable-data-list">

                            <template v-for="header in unTypedColumnHeaders" v-slot:[`item.${header.value}`]="{item}" >                        
                                <slot :name="columnSlotName(header)" :item="item">
                                    {{item[header.value]}}
                                </slot>
                            </template> 

                            <template v-slot:menu="{item}">
                                <slot name="menu" :item="item"></slot>
                            </template>

                        </Datatable>

                    <slot name="after-datatable"></slot>
                </v-card-text>
                <v-divider v-if="!dialog.noActions"></v-divider>
                <v-card-actions class="py-4" v-if="!dialog.noActions">
                    <slot name="footer-left">
                    </slot>
                    <v-spacer></v-spacer>                            
                    <slot name="footer-right">
                    </slot>
                    <div class="commands">
                        <slot name="footer-pre-actions"></slot>

                        <slot v-if="dialog.cancel" name="footer-cancel">
                            <ActionButton dusk="btn-footer-close" :disabled="dialog.isLoading" @click="dialog.onClose()">
                                <slot name="footer-cancel-text">{{dialog.cancel}}</slot>
                            </ActionButton>
                        </slot>
                        <slot name="footer-post-actions"></slot>
                    </div>
            </v-card-actions>           
        </v-card>

    </v-dialog>
</template>

<script setup>

    //
    // import DataListDialog from '@shared/ui/dialogs/DataListDialog'
    // 
    // let dlg = ref(
    //     new clsDialog("datalist_purchaseinvoice", "Inkoopfacturen", dt, {
    //         maxWidth:'1200px',
    //     })).value;
    // 




    // clsDataListDialog
    //

    import Icon from '@controls/icons/Icon'
    import HeaderButton from '@shared/ui/dialogs/HeaderActionButton'
    import ActionButton from '@controls/buttons/ActionButton'
    import Datatable from '@datatable/Datatable'
    import {computed} from 'vue'
    import ActionMenu from '@controls/menu/ActionMenu.vue'
    
    const props = defineProps({
        dialog: {
            type: [Object]
        },
        dt: {
            type: [Object]
        },
        cmdMenu: {
            type: [Boolean],
            default: false
        },
        useFilter: {
            type: [Boolean],
        },
    })

    function columnSlotName(item) {
        return `item.${item.value}`
    }

    // We want to override only untyped headers. 
    // typed headers are handled by the datatable. 
    var unTypedColumnHeaders = computed({
        get() {
            return (props.dt.headers||[]).filter( (header) => !header.type)
        }
    })


</script>
