import { Mark } from '@tiptap/core';
import utils from './tiptap-utils';

// Allow for a custom element <w40> in the markup. 
// W40 stands for width 40%.
const Highlight = Mark.create({
    name: 'w40',
    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    parseHTML() {
        return [
            {
                tag: 'w40',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['w40', HTMLAttributes /* core.mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)*/, 0];
    },
    addCommands() {
        return {
            setW40: () => ({ editor, commands }) => {
                utils.selectWord(editor,commands);
                commands.unsetMark("w25")
                return commands.setMark(this.name)
            },
            toggleW40: () => ({ editor, commands }) => {
                console.log('toggleW40')
                utils.selectWord(editor,commands);
                commands.unsetMark("w25")
                return commands.toggleMark(this.name)
            },
            unsetW40: () => ({ editor, commands }) => {
                utils.selectWord(editor,commands);
                return commands.unsetMark(this.name)
            },
        }
    },
    addKeyboardShortcuts() {
        return {};
    },
    addInputRules() {
        return [
        ];
    },
    addPasteRules() {
        return [];
    },
});


export { Highlight, Highlight as default };