import eventbus from '@app/eventbus'
import { onUnmounted } from 'vue'
import noty from '@lib/noty'

/**
 * Basic class for generating the backbones for dialogs to show items in a datatable. 
 * 
 * What is the reason for property setKeyOnOpen?
 * ******************************************************************************************************
 * Vue typically reuses the DOM as efficiently as possible. 
 * That is fine. But in case of dialogs, it means that once the dialog opened, and the components are mounted, 
 * the creation is finished.
 * When the dialog is closed, it is moved to shadow dom and when it is reopened, it is moved to DOM. 
 * This without going through the lifecycle again. 
 * 
 * In most cases this is perfectly fine, except when components used in the dialog must e.g. load new data on mount. 
 * Specificly, this is about datatables. When a dialog contains a datatable, which we typically do, the datatable is 
 * loaded once, and not again when the dialog is reopened for another select action. 
 * 
 * In this case, we force the dialog to go through all lifecycles by generating a unique key for the vue component.
 *     
 */
class clsDataListDialog {
    setKeyOnOpen= true;
    dlgKey      = undefined;
    modelName   = null
    dt          = null;
    maxWidth    = "850px"
    open        = false
    tab         = 0
    cancel      = "Sluit"
        
    cls         = "action-dialog dialog-data-list"
    icon        = "bullet-list"
    title       = "title"

    noSubTitle  = false

    noActions   = false
    isLoading     = false;
    fnReject      = null; 
    fnResolve     = null;


    // Default focusfield. 
    // Usage:
    //      <control ref="q"></control>
    // ...
    //    const q = ref()
    // ...
    //   new clsDataListDialog(....., {defaultFocus: ()=>q.value}) 
    defaultFocus = null 

    xl = 12;    // The columns dedicated to show the body in - xtra large device 
    lg = 12;    // The columns dedicated to show the body in - large device 
    sm = 12;    // The columns dedicated to show the body in - small device 

    setUniqueDialogKey() {
        if (!window._sldlg) {
            window._sldlg = 1;
        }
        window._sldlg++;
        this.dlgKey = window._sldlg + 1;
    }

    async closeDialog(force) {
        this.open = false;
    }
    openDialog() {
        if (this.setKeyOnOpen) {
            this.setUniqueDialogKey();
        }
        this.open = true;
        // Remove the context for a short period as the reference to the field is probably not yet resolved.
        setTimeout( ()=> this.setDefaultFocus(), 100)
        // this.setDefaultFocus();
    }
    setDefaultFocus() {
        if (!this.defaultFocus || !(this.defaultFocus instanceof Function)) {
            return;
        }
        let focusCtrl = this.defaultFocus();
        if (focusCtrl && focusCtrl.$el && focusCtrl.$el.getElementsByTagName) {
            var $el = focusCtrl.$el.getElementsByTagName('input');
            if ($el && $el.length && $el[0].focus) {
                $el[0].focus();
                $el[0].select();
            }
        }
    }

    get disabled() {
        return false;
    }

    // Overrides for functions like 'onBulkRemove', etc
    callbacks= {

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // 
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    onKeyDown(evt, b, c) {
        if (evt && evt.key == "Escape") {
            this.onClose()
        }
    }

    onClose() {
        this.closeDialog();
    }
    
    onOpen(params) {
        if (this.callbacks.onOpen) {
            this.callbacks.onOpen(params)
        }

        this.openDialog();
        this.tab = 0;
    }

    evtRegistrations = []
    registerEvents() {
        var self = this;
        this.evtRegistrations.push(
            // fnReject, fnResolve, modelName
            eventbus.dialog.open.onPromised( (fnResolve, fnReject, modelName, params) => {    
                if (modelName != self.modelName) {
                    return;
                }
                self.onOpen(params);
                this.fnReject      = fnReject;
                this.fnResolve     = fnResolve;
                this.noSubItemSelect = params && (params.noSubItemSelect);
                
                // Clear the existing filter as it may contain values for the previous search action
                for (var key in this.dt.filter) {
                    this.dt.filter[key] = null;
                }
                // Exclude specific ids.                                
                this.dt.filter.exclude = params && params.exclude || [];
                // Set the provided filter values. 
                // Note that you can not just set random values. In the datatable constructor, the filterable fields need to be defined. 
                // For an example, see DialogSelectProject.vue
                if (params && params.filter) {
                    for (var key in params.filter) {
                        this.dt.filter[key] = params.filter[key];
                    }                                    
                }

                return this.dlgKey;
            })
        )
    }
    cleanUp() {
        (this.evtRegistrations||[]).forEach( (fnUnRegister) => fnUnRegister() ) 
    }

    constructor(modelName, title, dt, options) {
        options = options||{}
        this.modelName = modelName;
        this.title = title;
        var self = this;
        this.dt = dt;
        this.registerEvents();
        this.setUniqueDialogKey();
        for (var key in options) {
            this[key] = options[key];
        }
        onUnmounted(() => {
            self.cleanUp();
        })
    }
}

export default clsDataListDialog;