import { clsModel, fnCreate } from '@cls/clsModel'
import { customfields as api } from '@/app/api'
import string from '@lib/string'
import {customfields, customfieldtypes} from '@/app/const/customfieldtypes.js'

var modelName = "customfield";
const fields = ["id", "id_entity", "id_optimit_type", "id_type", "name", "description", "default", "values", "mandatory"];

class clsCustomField extends clsModel {

    id_optimit_type  = null;
    id_entity        = null;

    _id_type        = null;
    name            = null;
    description     = null;
    default        = null;
    _values          = null;

    mandatory       = null;

    display = {
        mandatory:    true,     
        isMandatory:  true,           
        defaultvalue: true,        
    }

    get types() {        
        var showAll = !this.display?.types?.length;
        var allTypes = customfields; 
        if (showAll) {
            return allTypes;
        }
        return allTypes.filter( (t)=>this.display.types.find((dt)=>dt==t.id));
    }

    get values() {
        return this._values;
    }    
    set values(value) {
        this._values = value;
        if (this.isFilling) {
            return;
        }
        this.default = null;
    }

    get id_type() {
        return this._id_type;
    }    
    set id_type(value) {
        this._id_type = value;
        if (this.isFilling) {
            return;
        }

        switch (value) {
            case customfieldtypes.checkbox:   this.default = false; break;
            case customfieldtypes.text:       this.default = ""; break;
            case customfieldtypes.longtext:   this.default = ""; break;
            case customfieldtypes.combo:      this.default = ""; break;
            case customfieldtypes.amount:     this.default = 0; break;
            case customfieldtypes.percentage: this.default = 0; break;
            case customfieldtypes.number:     this.default = 0; break;
            case customfieldtypes.date:       this.default = ""; break;
            case customfieldtypes.time:       this.default = 0; break;    
        }
    }    
    get isCheckbox()   { return (this.id_type == customfieldtypes.checkbox);  }
    get isText()       { return (this.id_type == customfieldtypes.text);      }
    get isLongtext()   { return (this.id_type == customfieldtypes.longtext);  }
    get isCombo()      { return (this.id_type == customfieldtypes.combo);     }
    get isAmount()     { return (this.id_type == customfieldtypes.amount);    }
    get isPercentage() { return (this.id_type == customfieldtypes.percentage);}
    get isNumber()     { return (this.id_type == customfieldtypes.number);    }
    get isDate()       { return (this.id_type == customfieldtypes.date);      }
    get isTime()       { return (this.id_type == customfieldtypes.time);      }


    // Load by a number of parameters
    // Note that extraData has preference because params can contain data which is not intended for 
    // use in the api.
    apiLoad(id, params, extraData) {
        var loadParams = extraData || params ||{};
        if (!params.id && id) {
            params.id = id;
        }
        return this.api.loadByParameters(loadParams);
    }

    get showDefaultValue() {
        if (!this.display.defaultvalue) {
            return false;
        }
        if (this.id_type == customfields.combo && !this.values) {
            return false;
        }
        return true;
    }
    get modelRep() {
        return this.name;
    }    

    /**
     * Convert the comma separated values to combobox values
     */
    get comboItems() {
        var values = string.split(this.values, ",");
        return values.map( (x) => { return {id: x, name: x} });
    }

    fill(data) {
        data.id_type = data.id_type || customfields.text;
        data.mandatory = !!data.mandatory;
        super.fill(data);
    }

    // Set the display options which can be provided bia the parameters.
    _setDisplayOptions(objDisplay) {
        objDisplay=objDisplay||{};
        console.log('_setDisplayOptions, para: ', objDisplay)

        this.display.mandatory = (undefined == objDisplay.mandatory) ? true : !!objDisplay.mandatory;
        this.display.defaultvalue = (undefined == objDisplay.defaultvalue) ? true : !!objDisplay.defaultvalue;
        if (!this.display.mandatory) {
            this.mandatory = !!objDisplay.isMandatory; // default value for is mandatory
        }
        this.display.types = (undefined == objDisplay.types) ? [] : objDisplay.types;
    }
    /**
     * override the implementation when required 
     */
    async onAfterLoad(data, params) {

        params = params || {};
        this.id_optimit_type  = params.id_optimit_type;
        this.id_entity        = params.id_entity;

        this._disabled = !!params.disabled;

        if (string.isEmpty(this.id_optimit_type)) {
            throw "id_optimit_type is mandatory for custom fields.";
        }
        this._setDisplayOptions(params.display);
    }
    /**
     * override the implementation when required 
     */
    async onAfterCreate(defaults) {
        this._setDisplayOptions(defaults.display);
    }


    /**
     * Also include the id_entity and id_optimit_type in the data
     */
    toJSON() {
        var json = {
            id:             this.id, 
            id_entity:      this.id_entity,
            id_optimit_type:this.id_optimit_type,
            id_type:        this.id_type,
            name:           this.name,
            description:    this.description,
            values:         this.values,
            mandatory:      !!this.mandatory
        };
        if (this.display.defaultvalue) {
            json.default = `${this.default}`; // must be a string.
        }

        return json;
    }
    

    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsCustomField , 'clsCustomField');
