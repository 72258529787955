import noty from '@shared/lib/noty'
import eventbus from '@app/eventbus'
import action from '@app/action'

//
// Usage: 
//  import dlg from '@app/dlg'
//  dlg.open('unit', {id 22})
//  dlg.create('unit')
//
// For convenience, a method to open the pdf viewer is also included: 
// dlg.pdf( async () => api.loadPdf(id))
//

/**
 * Check whether the subAction can be executed on the given model. 
 * For example: 
 * -  
 * @returns 
 */
function _check(modelName, subAction /* = 'open' */) {
    var fullAction = `${modelName}.${subAction}`;

    if (!action.exists(fullAction)) {
        throw `Dialoog actie ${fullAction} not found.`;
    }
    if (!action.can(fullAction)) {
        noty.alert("U heeft geen rechten om deze taak uit te voeren.");
        return false;
    }
    return true;
}

/**
 * Example, open a relation with id 123: 
 *  dlg.open('relation', {id: 123})
 * 
 * For a note, we have one mechanism. 
 * So we have one note dialog for all entities (relations, invoice, etc). 
 * The rights however must be checked against other models. 
 * In this case, provide a rightsModelName in the extra parameter. That value will be used to 
 * check the rights agains. For example, extra = {rightsModelName: "relation_note"} 
 */
const dlg = {
    open: (modelName, options, extra) => {        
        if (!_check(extra?.rightsModelName || modelName, "open")) {
            return;
        }
        if (typeof(options) == 'function') {
            options = options();
        }
        if (typeof(extra) == 'function') {
            extra = extra();
        }
        return eventbus.dialog.open(modelName, options, extra);
    },
    create: (modelName, options, extra) => {
        if (!_check(extra?.rightsModelName || modelName, "create")) {
            return;
        }
        return eventbus.dialog.create(modelName, options, extra);
    },
    copy: (modelName, options, extra) => {
        if (!_check(extra?.rightsModelName || modelName, "copy")) {
            return;
        }
        return eventbus.dialog.copy(modelName, options, extra);
    }, 
    close: (modelName, options, extra) => {
        modelName = modelName || "all";
        return eventbus.dialog.close(modelName, options, extra);
    },
    // Open a dialog for showing pdf data.
    // fnDataRetrieval must resolve in the shown pdf.
    // Usage: 
    //    dlg.pdf( async () => api.getPdf(id));
    pdf: (fnDataRetrieval, fileName /* optional */, options /* optional */) => {
        eventbus.dialog.pdfviewer(async () => fnDataRetrieval(), fileName, options);
    },
    apdf: (params, fileName, options) => {
        eventbus.dialog.pdf(params, fileName, options);
    },

    promised: {
        // open and return the result, but when canceled, don't end up in an exception.
        // When false is returned, the dialog could not be opened or the dialog was closed with cancel.
        tryOpen: async function(modelName, options, extra) {
            try {
                if (!_check(extra?.rightsModelName || modelName, "open")) {
                    return false;
                }
                return await eventbus.dialog.open.promise(modelName, options, extra);
            }
            catch (e) {
                if (e.canceled) {
                    return false;
                }
                throw e;
            }
        },        
        open: async (modelName, options, extra) => {
            if (!_check(extra?.rightsModelName || modelName, "open")) {
                return;
            }
            return eventbus.dialog.open.promise(modelName, options, extra);
        },
        create: async (modelName, options, extra) => {
            if (!_check(extra?.rightsModelName || modelName, "create")) {
                return;
            }
            return eventbus.dialog.create.promise(modelName, options, extra);
        },
        copy: async (modelName, options, extra) => {
            if (!_check(extra?.rightsModelName || modelName, "copy")) {
                return;
            }
            return eventbus.dialog.copy.promise(modelName, options, extra);
        },     
    }
}

export default dlg;