<template>
    <v-card class="mx-auto rich-text-editor wysiwyg-editor" outlined>
        <v-toolbar v-if="!noToolbar" flat dense>
            <slot name="toolbar-left" v-bind:editor="editor"></slot>
            <v-btn v-if="canEditCmd" :disabled="compDisabled" class="ml-2" small icon v-for="(cmd, index) in commands" :key="index" @click="() => cmd.fn(editor)">
                <Icon :class="{ 'text-bold': editor && editor.isActive(cmd.name) }"  small :type="cmd.icon"></Icon>
            </v-btn>
            <v-btn v-if="canOrderedList" :disabled="compDisabled" class="ml-2" small icon @click="() => cmdOrderedList.fn(editor)">
                <Icon :class="{ 'text-bold': editor && editor.isActive(cmdOrderedList.name) }" small :type="cmdOrderedList.icon"></Icon>
            </v-btn>
            <v-btn :disabled="compDisabled" v-if="canWordWidth" style="width:auto;" class="mr-6" depressed icon title="Toggle breedte" @click="() => cmdWX.fn(editor)">
                <Icon :class="{ 'text-bold': editor && (editor.isActive(cmdW20.name)||editor.isActive(cmdW30.name)||editor.isActive(cmdW40.name)||editor.isActive(cmdW50.name)) }" small type="width"></Icon>
            </v-btn>
            
            <slot name="toolbar-cmd-right"  v-bind:editor="editor"></slot>
            <v-spacer></v-spacer>
            <v-menu offset-y v-if="variablesLeft1&&variablesLeft1.length">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="compDisabled"  style="width:auto;" class="mr-6" depressed icon v-bind="attrs" v-on="on" title="Voeg een variabele in">
                        <slot name="variable-trigger">
                            <span class="">{{ variablesLeft1Name }}<Icon class="ml-1" small type="dropdown"></Icon></span>
                        </slot>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item @click="() => editor.chain().focus().insertContent(`${item.value}`).run()"
                        v-for="(item, index) in variablesLeft1"
                        :key="index"
                        link
                    >
                    <v-list-item-title >{{ item.label }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> 
            <v-menu offset-y v-if="variablesLeft2&&variablesLeft2.length">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="compDisabled"  style="width:auto;" class="mr-6" depressed icon v-bind="attrs" v-on="on" title="Voeg een variabele in">
                        <slot name="variable-trigger">
                            <span class="">{{ variablesLeft2Name }}<Icon class="ml-1" small type="dropdown"></Icon></span>
                        </slot>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item @click="() => editor.chain().focus().insertContent(`${item.value}`).run()"
                        v-for="(item, index) in variablesLeft2"
                        :key="index"
                        link
                    >
                    <v-list-item-title >{{ item.label }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> 
            <v-menu offset-y v-if="variables&&variables.length">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="compDisabled" style="width:auto;" class="mr-6" depressed icon v-bind="attrs" v-on="on" title="Voeg een variabele in">
                        <slot name="variable-trigger">
                            <span class="">{{ variablesName }}<Icon class="ml-1" small type="dropdown"></Icon></span>
                        </slot>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item @click="() => editor.chain().focus().insertContent(`${item.value}`).run()"
                        v-for="(item, index) in variables"
                        :key="index"
                        link
                    >
                    <v-list-item-title >{{ item.label }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> 
            <slot name="toolbar-right"  v-bind:editor="editor"></slot>
        </v-toolbar>

        <v-divider/>
        <v-card-text class="wysiwyg-content pa-2" :class="classHeight">            
            <editor-content class="rich-text-area" :editor="editor" :editable="!compDisabled&&!skeleton"/>
        </v-card-text>
  </v-card>

</template>

<script setup>
    import Icon from '@controls/icons/Icon'

    const commands = [
        {name: 'bold', icon: 'bold', fn: (editor) => {editor.chain().focus().toggleBold().run()}},
        {name: 'italic', icon: 'italic', fn: (editor) => {editor.chain().focus().toggleItalic().run()}},
        {name: 'bulletList', icon: 'bullet-list', fn: (editor) => {editor.chain().focus().toggleBulletList().run()}},
    ];
    const cmdOrderedList = {name: 'orderedList', icon: 'ordered-list', fn: (editor) => {editor.chain().focus().toggleOrderedList().run()}};
    const cmdW20 = {name: 'w20', icon: 'width', fn: (editor) => {editor.chain().focus().toggleW20().run()}};
    const cmdW30 = {name: 'w30', icon: 'width', fn: (editor) => {editor.chain().focus().toggleW30().run()}};
    const cmdW40 = {name: 'w40', icon: 'width', fn: (editor) => {editor.chain().focus().toggleW40().run()}};
    const cmdW50 = {name: 'w50', icon: 'width', fn: (editor) => {editor.chain().focus().toggleW50().run()}};
    const cmdWX =  {name: 'wx', icon: 'width', fn: (editor) => {editor.chain().focus().toggleWX(editor).run()}};
</script>

<script>
//   import RichTextArea from '@controls/input/TextArea'
//   <TextArea :skeleton="model.isDataLoading" placeholder="Notitie" v-model="model.note"></TextArea>

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

// We need text style, width and display for being able to display pre-formatted 'table'-like data. 
// For example, invoice reminder data. 
import TextStyle from "@tiptap/extension-text-style";
import TextWidth from "@lib/ui/tiptap-extension-text-width";
import TextDisplay from "@lib/ui/tiptap-extension-text-display";
import Highlight from "@lib/ui/tiptap-extension-text-highlight";
import Success from "@lib/ui/tiptap-extension-text-success";
import W20 from "@lib/ui/tiptap-extension-text-w20";
import W30 from "@lib/ui/tiptap-extension-text-w30";
import W40 from "@lib/ui/tiptap-extension-text-w40";
import W50 from "@lib/ui/tiptap-extension-text-w50";
import WX  from "@lib/ui/tiptap-extension-text-wx";

export default {
    components: {
        EditorContent,
    },

    props: {
        value: {
        type: String,
        default: '',
        },
        disabled: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        xxl: {
            type: [Boolean]
        },
        xl: {
            type: [Boolean]
        },
        lg: {
            type: [Boolean]
        },
        md: {
            type: [Boolean]
        },
        sm: {
            type: [Boolean]
        },
        xs: {
            type: [Boolean]
        },
        variables: {
            type: [Object, Array],
            default: () => []
        },
        variablesName: {
            type: [String],
            default: "variabele"
        },
        variablesLeft1: {
            type: [Object, Array],
            default: () => []
        },
        variablesLeft1Name: {
            type: [String],
            default: "Variabele"
        },
        variablesLeft2: {
            type: [Object, Array],
            default: () => []
        },
        variablesLeft2Name: {
            type: [String],
            default: "Variabele"
        },
        canOrderedList: {
            type: [Boolean]
        },
        canWordWidth: {
            type: [Boolean]
        },
        canEditCmd: {
            type: [Boolean],
            default: true
        },
        model: {
            type: [Object, Array],
            default: () => {}
        },
        noToolbar: {
            type: [Boolean]
        }
    },

    data() {
        return {
        editor: null,
        }
    },
    computed: {
        classHeight: function() {
            var cls = "sm";
            if (this.xxl) { cls = "xxl"; }
            else if (this.xl) { cls = "xl"; }
            else if (this.lg) { cls = "lg"; }
            else if (this.md) { cls = "md"; }
            else if (this.sm) { cls = "sm"; }
            else if (this.xs) { cls = "xs"; }
            return `wysiwyg-${cls}`;
        },
        compDisabled: function() {
            if (this.disabled) {
                return true;
            }
            return this.model && this.model.disabled;
        }
    },
    watch: {
        value(value) {
            if (!this.editor) {
                return; // Skip in init phase.
            }
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
        disabled(value) {
            this.editor.setOptions({editable: !value})            
        }
    },

    mounted() {
        this.editor = new Editor({
        content: this.value,
        extensions: [
            StarterKit.configure({
                heading: false,
                orderedList: true,
                horizontalRule: false,
                blockQuote: false,
            }),
            TextStyle,
            TextWidth,
            TextDisplay,
            Highlight.extend({inclusive: false}), // Do not allow to expand the text.
            Success.extend({inclusive: false}),
            W20.extend({inclusive: false}),
            W30.extend({inclusive: false}),
            W40.extend({inclusive: false}),
            W50.extend({inclusive: false}),
            WX.extend({inclusive: false}),
        ],
        onUpdate: () => {
            // HTML
            this.$emit('input', this.editor.getHTML())

            // JSON
            // this.$emit('input', this.editor.getJSON())
        },
        })
        this.editor.setOptions({editable: !this.disabled})            
        window.eddie = this.editor;
    },

    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>
