import clsSettings from '@cls/clsSettings'
import {settings as api} from '@app/api'

var modelName="document"
class clsDocumentSettings extends clsSettings {

    /**
     * clone the current setting.
     * load fresh data from the server.
     * autoLoad: load the data automagically
     * noDestroy: prevent object being cleaned up on unmount.
     *            Actually, can not think for a use case.
     */
    clone(autoLoad, noDestroy) {
        var obj = new clsDocumentSettings();

        if (!noDestroy) {
            obj.registerDestroyOnUnmount();            
        }
        if (autoLoad) {
            obj.load();
        }
    
        return obj;
    }
    
    isLogoUploading                  = false;
    isLetterUploading                = false;
    
    url_letter                       = null;
    url_logo                         = null;
    id_file_document_letter          = null;
    letter_show                      = null;

    // Followup letter settings
    isFollowupLetterUploading        = false;
    url_letter_followup              = null;
    letter_type_followup             = "n"; // 'n' = none, 'l' - standard letter, 'f' - followup letter
    id_file_document_letter_followup = null;
    use_margin_top_followup              = false;
    margin_top_followup                  = null;

    _use_letter_followup = false; // calculated.
    get use_letter_followup() {
        if (!this.letter_show) {
            return false;
        }
        if (!this.url_letter) {
            return false;
        }
        return this._use_letter_followup; 
    }    
    set use_letter_followup(value) {
        if (!value) {
            this._use_letter_followup = false;
            this.letter_type_followup = 'l';
        } else {
            this._use_letter_followup = true;
            this.letter_type_followup = 'n';    // letter by default.
        }
    }
    
    id_file_document_logo            = null;
    logo_show                        = null;
    logo_margin_left                 = null;
    logo_margin_top                  = null;
    logo_width                       = null;
    company_info_show                = null;
    company_info_margin_right   = null;
    company_info_margin_top     = null;
    font                         = null;
    fontsize                         = null;
    color_custom                 = null;
    color_accent                 = null;
    recipient_margin_left            = null;
    recipient_margin_top             = null;
    margin_left                      = null;
    margin_right                     = null;
    margin_top                       = null;
    margin_bottom                    = null;
    content_margin_top               = null;
    id_theme                            = null;
    company                          = [];
    _use_custom_margins = false;

    get use_custom_margins() { return this._use_custom_margins;}
    set use_custom_margins(v) { 
        if (!v) {
            this.margin_top = 12 
            this.margin_bottom = 12 
            this.margin_left = 12 
            this.margin_right = 12
        }
        this._use_custom_margins = v;
    }
    constructor() {
        super({modelName: modelName});
    }

    removeCompanyLine(lineRemove) {
        this.company = this.company.filter( (line) => line != lineRemove);
    }
    addLine(cols) {
        this.company.push( {
            cols : cols?cols:1,
            text1: "",
            text1_style: "",
            text2: "",
            text2_style: "",
            //sequence:
        });
    }
    async onLogoSelected(files) {
        this.isLogoUploading = true;
        try {
            var result = await api.uploadFileList(files, {
                url: 'upload/document/logo', maxFiles: 1
            })
            
            this.id_file_document_logo = result.data.id;
            this.url_logo = result.data.url;
            this.logo_show = true;
        } 
        finally {
            this.isLogoUploading = false;
        }
        return result;
    }
    async onLetterSelected(files) {
        this.isLetterUploading = true;
        try {
            var result = await api.uploadFileList(files, {
                url: 'upload/document/letter', maxFiles: 1
            })
            
            this.id_file_document_letter = result.data.id;
            this.url_letter = result.data.url;
            this.letter_show = true;
        } 
        finally {
            this.isLetterUploading = false;
        }
        return result;
    }
    async onFollowupLetterSelected(files) {
        this.isFollowupLetterUploading = true;
        try {
            var result = await api.uploadFileList(files, {
                url: 'upload/document/followupletter', maxFiles: 1
            })
            
            this.id_file_document_letter_followup = result.data.id;
            this.url_letter_followup = result.data.url;
            this.letter_type_followup = 'f';
        } 
        finally {
            this.isFollowupLetterUploading = false;
        }
        return result;
    }
    toggleStyle(line, n) {
        if (n == 2) {
            line.text2_style = line.text2_style ? "" : "b";
        }
        else {
            line.text1_style = line.text1_style ? "" : "b";
        }
    }

    fill(data) {
        data = data ||{};
        if ( !data.logo_width) {
            data.logo_width = 60;
        }
        this.url_letter                     = data.url_letter;
        this.url_logo                       = data.url_logo;        
        this.id_file_document_letter        = data.id_file_document_letter;
        this.letter_show                    = data.letter_show;
        this.id_file_document_logo          = data.id_file_document_logo;
        this.logo_show                      = data.logo_show;
        this.logo_margin_left               = data.logo_margin_left;
        this.logo_margin_top                = data.logo_margin_top;
        this.logo_width                     = data.logo_width;
        this.company_info_show              = data.company_info_show;
        this.company_info_margin_right      = data.company_info_margin_right;
        this.company_info_margin_top        = data.company_info_margin_top;
        this.font                           = data.font;
        this.fontsize                       = data.fontsize;
        this.color_custom                   = data.color_custom;
        this.color_accent                   = data.color_accent;
        this.recipient_margin_left          = data.recipient_margin_left;
        this.recipient_margin_top           = data.recipient_margin_top;
        this.margin_left                    = data.margin_left;
        this.margin_right                   = data.margin_right;
        this.margin_top                     = data.margin_top;
        this.margin_bottom                  = data.margin_bottom;
        this.content_margin_top             = data.content_margin_top;
        this.id_theme                       = data.id_theme || 1;
        
        // Followup page settings
        this.url_letter_followup              = data.url_letter_followup ||"";
        this.letter_type_followup             = data.letter_type_followup || "l";
        this.id_file_document_letter_followup = data.id_file_document_letter_followup
        this.use_margin_top_followup              = !!data.use_margin_top_followup 
        this.margin_top_followup                  = data.margin_top_followup || 50;
        
        // Letter followup is enabled when a default letter is selected and the saved setting is not 'same as letter' ('l').
        this._use_letter_followup             = !!this.url_letter && this.letter_show && this.letter_type_followup != 'l'; 
        this.company                        = data.company;

        if (this.margin_top == 12 && this.margin_bottom == 12 && this.margin_left == 12 && this.margin_right == 12) {
            this.use_custom_margins = false;
        } else {
            this.use_custom_margins = true;            
        }
    }

    toJSON() {
        return {
            url_letter                     : this.url_letter,
            url_logo                       : this.url_logo,        
            id_file_document_letter        : this.id_file_document_letter,
            letter_show                    : this.letter_show,
            id_file_document_logo          : this.id_file_document_logo,
            logo_show                      : this.logo_show,
            logo_margin_left               : this.logo_margin_left,
            logo_margin_top                : this.logo_margin_top,
            logo_width                     : this.logo_width,
            company_info_show              : this.company_info_show,
            company_info_margin_right : this.company_info_margin_right,
            company_info_margin_top   : this.company_info_margin_top,
            font                       : this.font,
            fontsize                       : this.fontsize,
            color_custom               : this.color_custom,
            color_accent               : this.color_accent,
            recipient_margin_left          : this.recipient_margin_left,
            recipient_margin_top           : this.recipient_margin_top,
            margin_left                    : this.margin_left,
            margin_right                   : this.margin_right,
            margin_top                     : this.margin_top,
            margin_bottom                  : this.margin_bottom,
            content_margin_top             : this.content_margin_top,
            id_theme                          : this.id_theme,
            company                        : this.company ,

            letter_type_followup             : this.letter_type_followup,
            id_file_document_letter_followup : this.id_file_document_letter_followup,
            use_margin_top_followup           : this.use_margin_top_followup,
            margin_top_followup               : this.margin_top_followup,
    
        };
    }

}

export default clsDocumentSettings;
