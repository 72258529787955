<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>
                <Row :l="colL" label="Naam">
                    <TextField max-length="255" dusk="name" placeholder="Naam" v-model="model.name" :model="model" :rules="model.rules.mandatory" ></TextField>
                </Row>
                <Row :l="colL" label="Type">
                    <ActionCombo placeholder="Type" dusk="type" v-model='model.id_type' :model='model' :items='model.types' :rules='model.rules.mandatory' ></ActionCombo>
                </Row>
                <Row v-if="model.isCombo" :l="colL" label="Opties">
                    <TextField dusk="values" placeholder="waarde1,waarde2,..." v-model="model.values" :model="model" :rules="model.rules.values" ></TextField>
                </Row>
                <Row :l="colL" label="Omschrijving">
                    <TextArea max-length="255" dusk="description" placeholder="Omschrijving" v-model="model.description" :model="model"></TextArea>
                    <template v-slot:info>Omschrijving is optioneel en dient als helpfunctie bij invullen contract.</template>
                </Row>
                <Row v-if="model.display.mandatory" :l="colL" label="Veld is verplicht">
                    <Checkbox switcher dusk="mandatory" :model='model' v-model='model.mandatory'></Checkbox>
                </Row>
                <v-divider v-if="model.showDefaultValue" class="mb-2" ></v-divider>
                <Row v-if="model.showDefaultValue" :l="colL" label="Standaard waarde">
                    <Checkbox    v-if="model.isCheckbox" dusk="checkboxdefault" switcher :model='model' v-model='model.default'></Checkbox>
                    <TextField   v-else-if="model.isText" placeholder="Optioneel" :model='model' :rules='model.rules.default' v-model='model.default'></TextField>
                    <TextArea    v-else-if="model.isLongtext" :model='model' v-model='model.default'></TextArea>
                    <ActionCombo v-else-if="model.isCombo" dusk="default" :model="model" :items='model.comboItems' :rules='model.rules.default' v-model='model.default' ></ActionCombo>                    
                    <NumberField v-else-if="model.isAmount" amount dusk='default' :model='model' :rules='model.rules.default' v-model='model.default'></NumberField>
                    <NumberField v-else-if="model.isPercentage" percentage dusk='default' :model='model' :rules='model.rules.default' v-model='model.default'></NumberField>
                    <NumberField v-else-if="model.isNumber" decimal dusk='default' :model='model' :rules='model.rules.default' v-model='model.default'></NumberField>
                    <DatePicker  v-else-if="model.isDate" dusk="default" :model="model" v-model='model.default' ></DatePicker>
                    <TimeField   v-else-if="model.isTime" dusk="default" :model='model' v-model='model.default'></TimeField>

                </Row>
            </Form>
        </v-form>
    </Dialog>
</template>



<script setup>
    import useModel from '../model/customfield'
    import Form from '@controls/forms/Form'
    import TextArea from '@controls/input/TextArea'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Checkbox from '@controls/check/Checkbox.vue'
    import NumberField from '@controls/input/NumberField'
    import ActionCombo from '@controls/combo/ActionCombo'
    import TimeField from '@controls/input/TimeField'
    import DatePicker from '@controls/input/DatePicker'

    import { computed, ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import Row from '@controls/forms/Row'
    const colL={sm:4,lg:3}
    const colR={sm:8,lg:6}    

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("customfield", "Invoerveld", model, [form])).value;

    window.csdlg = dlg;

</script>
