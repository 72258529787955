import clsApiPost from '@cls/clsApiPost'

// import clsContractApi from '@/features/contracts/contract/api'; 

class clsContractApi extends clsApiPost {


    addExternalSignee(id_contract, email, name) { 
        return this.post(`signee/ext/save`, {id_entity:id_contract, email: email, name: name}); 
    }
    removeExternalSignee(id_contract, id) { 
        return this.post(`signee/ext/remove`, {id_entity:id_contract, id: id}); 
    }
    send(model) { 
        return this.post(`send`, model); 
    }
    loadSendData(id_contract) { 
        return this.post(`loadsenddata`, {id: id_contract}); 
    }
    startCorrection(id_contract) { 
        return this.post(`correction/start`, {id: id_contract}); 
    }
    stopCorrection(id_contract) { 
        return this.post(`correction/stop`, {id: id_contract}); 
    }
    approve(ids) { 
        return this.post(`approve`, {ids: ids}); 
    }
            
}

export default clsContractApi;
