<template>
    <div class="adobe-pdf-viewer-container">
        <div class="adobe-pdf-viewer" :id="id"></div>            
    </div>
</template>

<script setup>
    // Usage: 
    // <v-card>
    //   <v-card-text>
    //      <PdfViewer ref="pdfviewer"></PdfViewer>
    //   </v-card-text>                        
    // </v-card>
    // 
    // import PdfViewer from '@shared/features/pdf/ViewerAdobe'
    // 
    // let pdfviewer = ref(null);         
    // var promise =  model.downloadExample();
    // pdfviewer.value.api.loadRaw(promise, "bestand.pdf")
    // 


    import string from '@lib/string'
    import pdfErrorDoc from '@cls/pdfErrorDoc'

    const clientId = process.env.VUE_APP_ADOBE_CLIENTID || "";
    const props = defineProps({
        id: {
            type: [String, Number],
            default: "adobe-dc-view"
        }
    });    
    const canApplyStyling = false;
    class clsAdobeViewer {
        id = null;
        adobeDCView = null;

        constructor(id) {
            this.id = id;
        }
        previewOptions() {
            return {
                showDownloadPDF: true,
                showAnnotationTools: false, 
                showZoomControl: true,
                showPrintPDF: false, 
                showThumbnails: true, 
                showBookmarks: false, 
                showFullScreenViewButton: true,
                enableFormFilling: false, 
                // embedMode: "FULL_WINDOW",
                // enableLinearization: false, // Performance related 
                enableAnnotationAPIs: false,
                includePDFAnnotations: false,
                enableSearchAPIs: false, 
                // showDisabledSaveButton: false, // Not sure.        
                // focusOnRendering: false, // Not sure
                defaultViewMode: "FIT_WIDTH",
            };
        }

        init() {
            // For every pdf view, a new instance must be mounted on the div.
            this.adobeDCView = new AdobeDC.View({clientId: clientId, divId: this.id, locale: 'nl-NL'});
        }

        // Applying styling to the adobe iframe does not work as the frame is loaded 
        // Cross-original.
        applyStyling(v) {
            
            if (!canApplyStyling) {
                return;
            }

            var idIFrame = `iframe-${props.id}`; // e.g. iframe-dlg-adobe-dc-view
            
//            document.getElementById(idIFrame).onload = function() {
                var iframe = document.getElementById(idIFrame).contentWindow.document;
                var style = document.createElement('style');
                style.innerHTML = `
                    .spectrum-ButtonGroup {
                        border: 2px solid orange;
                        position:absolute;
                        top:8px;
                        right:100px;
                        flex-direction: row !important;    
                    }
                `;
                iframe.head.appendChild(style);
  //          };
        }

        // Untested.
        loadUrl(url, filename) {
            throw "NYT";
        //    this.init();
        //    this.adobeDCView.previewFile({
        //        content:{location: {url: url}},
        //        metaData:{fileName: filename}
        //    }, {});
        }

        /**
         * Load an error document which we can show when rendering / fetching fails.
         */
        async loadErrorDocumentBuffer() {
            return await string.base64DecodeToArrayBuffer(pdfErrorDoc.base64());
        }
        // Load raw data. The data can be delivered via a promise or as-is. 
        // As the viewer presents a loader image, it is prefered to return a promise.
        // The final data is expected to be an ArrayBuffer - which is default when downloading 
        // binary data like pdf data via the http lib.
        //  
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // Example loading data via a promise:
        // 
        // clsModel: {
        //     async downloadExample() {
        //         return api.postPDF("pdf", {id: this.id});
        //     }
        // }  
        // ... 
        // pdfviewer.value.api.loadRawPromised(model.downloadExample(), "bestand.pdf")
        // 
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // Example loading raw data via a promise:
        // 
        // clsModel: {
        //     async downloadExample() {
        //         return api.postPDF("pdf", {id: this.id});
        //     }
        // }  
        // ...
        // var data = await model.downloadExample();
        // pdfviewer.value.api.loadRawPromised(data, "bestand.pdf")
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        async loadRaw(promise, filename, options) {
            var p = new Promise( async (resolve, reject) => {
                try {
                    var result = await promise;
                    var data = result.data || result;
                    resolve(data);
                } catch (e) {
                    // In essence, we could: return this.loadBase64(pdfErrorDoc.base64(), "Fout");
                    // However, that method could have load issues on itself which makes the flow harder to understand.
                    var buffer = await this.loadErrorDocumentBuffer();
                    resolve(buffer)
                }
            })
            // var data = await promise;
            this.init();
            let previewOptions = this.previewOptions();            
            this.adobeDCView.previewFile({
                content: { promise: p }, 
                metaData: { fileName: filename||null},             
            }, previewOptions);
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        // 
        // Load base64 data, either via a promise or directly the base data.
        // Example: 
        // 
        //   const emptyDocument = "JVBERi0xLjEKJeLjz9MKMSAwIG9iaiAKPDwKL1BhZ2VzIDIgMCBSCi9UeXBlIC9DYXRhbG9nCj4+CmVuZG9iaiAKMiAwIG9iaiAKPDwKL01lZGlhQm94IFswIDAgNTk1IDg0Ml0KL0tpZHMgWzMgMCBSXQovQ291bnQgMQovVHlwZSAvUGFnZXMKPj4KZW5kb2JqIAozIDAgb2JqIAo8PAovUGFyZW50IDIgMCBSCi9NZWRpYUJveCBbMCAwIDU5NSA4NDJdCi9UeXBlIC9QYWdlCj4+CmVuZG9iaiB4cmVmCjAgNAowMDAwMDAwMDAwIDY1NTM1IGYgCjAwMDAwMDAwMTUgMDAwMDAgbiAKMDAwMDAwMDA2NiAwMDAwMCBuIAowMDAwMDAwMTQ5IDAwMDAwIG4gCnRyYWlsZXIKCjw8Ci9Sb290IDEgMCBSCi9TaXplIDQKPj4Kc3RhcnR4cmVmCjIyMQolJUVPRgo=";
        //   pdfviewer.value.api.loadBase64(emptyDocument, "EMPTY")
        // 
        // 
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        async loadBase64(base64PDF, filename, options) {
            this.init();
            var p = new Promise( async (resolve, reject) => {
                var result = await base64PDF;
                var data = result.data || result;
                var buffer = await string.base64DecodeToArrayBuffer(data);
                resolve(buffer);
            })
            let previewOptions = this.previewOptions();            
            var previewFilePromise = this.adobeDCView.previewFile({
                content: { promise: p }, 
                metaData: { fileName: filename||null},             
            }, previewOptions);
            var self = this;
            previewFilePromise.then(adobeViewer => {
                self.applyStyling(adobeViewer);
            });
        }
        // Load a local file. 
        // Example: 
        // <input type="file" accept="application/pdf" @change="onFileSelected" ref="fileInput"> 
        // function onFileSelected() {
        //      let files = fileInput.value.files;
        //      pdfviewer.value.api.loadLocalFile(files[0])
        //  }
        // 
        loadLocalFile(file, options) {
            this.init();
            if (!file) {
                return;
            }
            let reader = new FileReader();
            let previewOptions = this.previewOptions();            
            var self = this;    
            reader.onloadend = async function(e) {
                let filePromise = Promise.resolve(e.target.result);
                await self.adobeDCView.previewFile({
                    content: { promise: filePromise }, 
                    metaData: { fileName: file.name },                 
                }, previewOptions);
            };
            reader.readAsArrayBuffer(file);
        }
    }

    const api = new clsAdobeViewer(props.id);

    defineExpose({
      api
    })
</script>
