<template>
    <Dialog :dialog="dlg" :dt="dt">
        <template v-slot:menu ="{ item }">
            <ActionMenuItem :action="`purchaseinvoice.open`" @click="dt.openDialog(item)"></ActionMenuItem>
            <slot name="menu">
            </slot>
        </template>

    </Dialog>
</template>


<script setup>

    // import DialogPurchaseInvoiceData from '@/ui/dialogs/purchaseinvoice/DialogPurchaseInvoiceData.vue'    
    // <DialogPurchaseInvoiceData/>

    import useDataTable from '@app/useDataTable'
    import Dialog from '@shared/ui/dialogs/DataListDialog'
    import { purchaseinvoices as api } from '@app/api'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDataListDialog'
    import Constants from '@app/consts'
    import ActionMenuItem from '@controls/menu/ActionMenuItem.vue'

    const id_optimit_type = Constants.optimit_types.purchaseinvoice;

    var headers = [
        { type: 'cmd'},
        { type: 'flag', flagType: `purchaseinvoice_flag`},
        { type: 'note', noteType: `purchaseinvoice_note`},
        { visible: false, text: "Status",                   value: "pi_status",      },
        { visible: false, text: "Type",                     value: "type",                  fmt: (v, item) => ((v == 'pin') ? 'pinbon' : (v == 'cash') ? 'kasbon' : 'factuur')} ,
        { visible: false, text: "Betaald",                  value: "paid_status",           fmt: (v, item) => ((v == 'paid') ? 'Ja' : (v == 'pending') ? 'Onderweg' : 'Nee')},
        { visible: false, text: "Betaalopdracht",           value: "po_number",      },        
        { visible: false, text: "Dispuut",                  value: "dispute",                           },
        { text: "Relatie",                  value: "rel_name", },
        { text: "Factuurnr",                value: "invoicenr", },
        { text: "Factuurdatum",             value: "invoicedate",    type: 'date'   },
        { visible: false, text: "Vervaldatum",              value: "invoiceduedate", type: 'date',      },
        { footer: 'amount', text: "Incl BTW",                 value: "total_payable",  type: 'amount', crnc_field:"crnc_code" },
        { footer: 'amount', text: "Excl BTW",                 value: "total_excl",     type: 'amount', crnc_field:"crnc_code"     },
        { footer: 'amount', text: "Projectbedrag Excl BTW",                 value: "pidl_total",     type: 'amount', crnc_field:"crnc_code"     },
        { footer: 'amount', visible: false, text: "BTW",                      value: "total_vat",      type: 'amount', crnc_field:"crnc_code"     },
        { visible: false, text: "Relatie BTW nummer",       value: "rel_coc_number",                    },
        { visible: false, text: "incasso",                  value: "incasso",        type: 'yesno'      },
        { visible: false, text: "IBAN",                     value: "iban",                              },
        { footer: 'amount', visible: false, text: "G bedrag",                 value: "g_amount",       type: 'amount', crnc_field:"crnc_code",    },
        { visible: false, text: "G-rekening",               value: "ibang",                             },
        { footer: 'amount', visible: false, text: "Betalingskorting",         value: "discount",       type: 'amount', crnc_field:"crnc_code",    },
        { visible: false, text: "Afzender",                 value: "pi_fromaddress",                    },
        { visible: false, text: "Onderwerp",                value: "pi_summary",                        },
		{ visible: false, isUtc: true, text: "Gewijzigd",                value: "updated_at",    type: 'datetime', isUtc: true},
		{ visible: false, text: "Gewijzigd door",           value: "updated_by",                        },
		{ visible: false, isUtc: true, text: "Aangemaakt",               value: "created_at",    type: 'datetime', isUtc: true, sort: 'desc'},
		{ visible: false, text: "Aangemaakt door",          value: "created_by",                        },
		{ visible: false, text: "Boekhoudnummer",           value: "accountancy_number",                },
    ]

    var dt = useDataTable("datalist_purchaseinvoice", "purchaseinvoice", api, headers, {
        actionDblClick:false,
        showFootSlots:true,
        itemName: {
            prefix: 'de',
            single: 'inkoopfactuur',
            plural: 'inkoopfacturen',
        }, 
        itemsPerPageOptions: [ 200, 500],
        itemsPerPage: 200,
        filter: {
            selectfor: null,
            id_project: null,
            id_relation: null,
        }
    })

    let dlg = ref(
        new clsDialog("datalist_purchaseinvoice", "Inkoopfacturen", dt, {
            maxWidth:'1200px',
        })).value;


    window.dldlg = dlg;
</script>
