//
// Usage: 
//  import clsIcon from '@shared/lib/core/clsIcon'
//

class clsIcon {

    types = {


        "accessibility":                   "fa-light fa-universal-acces",
        "accesslog":                       "fa-light fa-signature-lock",             
        "accountancy":                     "fa-light fa-link",
        "add":                             "fa-light fa-plus", 
        "alert":                           "fa-light fa-hexagon-exclamation",
        "align-center":                    "fa-light fa-align-center",
        "align-left":                      "fa-light fa-align-left",
        "align-right":                     "fa-light fa-align-right",
        "apps":                            "fa-light fa-grid-2",
        "archive":                         "fa-light fa-inbox-in",
        "arrowleft":                       "fa-light fa-arrow-left",
        "arrowright":                      "fa-light fa-arrow-right",
        "attachment":                      "fa-light fa-paperclip",
        "attachments":                     "fa-light fa-folder",
        "attachmentsfilled":               "fa-solid fa-folder",        
        "authenticate":                    "fa-light fa-fingerprint",
        "back":                            "fa-light fa-arrow-turn-left",
        "bank":                            "fa-light fa-building-columns",
        "bell":                            "fa-light fa-bell-ring",
        "bold":                            "fa-light fa-bold",
        "rt-bold":                         "fa-bold",                   // bold for rich text line
        "brew":                            "fa-light fa-flask",
        "bullet-list":                     "fa-light fa-list-ul",
        "rt-bullet-list":                  "fa-list-ul",                // bullet list for rich text line
        "burger":                          "fa-light fa-bars",
        "calculator":                      "fa-light fa-calculator",
        "calendar-check":                  "fa-light fa-calendar-check",
        "calendar-minus":                  "fa-light fa-calendar-minus",
        "calendar-plus":                   "fa-light fa-calendar-plus",
        "calendar-remove":                 "fa-light fa-calendar-xmark",
        "calendar":                        "fa-light fa-calendar",
        "chapter":                         "fa-light fa-layer-group",
        "check":                           "fa-light fa-check",
        "chevrondown":                     "fa-light fa-chevron-down",
        "chevronleft":                     "fa-light fa-chevron-left",
        "chevronright":                    "fa-light fa-chevron-right",
        "chevronup":                       "fa-light fa-chevron-up",
        "child-expanded":                  "fa-light fa-arrow-turn-down-right",
        "circle-open":                     "fa-light fa-circle-o",
        "circle-check":                    "fa-light fa-check-circle",
        "circle-check-filled":             "fa-solid fa-check-circle",
        "circle-x":                        "fa-light fa-times-circle",
        "close":                           "fa-light fa-xmark",
        "collapse":                        "fa-light fa-square-minus",
        "combine_amount":                  "fa-light fa-merge",
        "company":                         "fa-light fa-buildings",
        "contract":                        "fa-light fa-file-contract",
        "confirm":                         "fa-light fa-circle-check",
        "copy":                            "fa-light fa-copy",
        "copy2":                           "fa-light fa-copy",
        "costtype":                        "fa-light fa-chart-pyramid",
        "create":                          "fa-light fa-plus", 
        "credit":                          "fa-light fa-file-minus",
        "creditrestriction":               "fa-light fa-file-minus",        
        "credit_restriction":              "fa-light fa-file-minus",        
        "customfield":                     "fa-light fa-input-pipe",
        "dashboard":                       "fa-light fa-chart-pie",
        "database":                        "fa-light fa-face-viewfinder",
        "deal":                            "fa-light fa-handshake-angle",
        "defaultnotice":                   "fa-light fa-calendar-circle-exclamation",
        "delete":                          "fa-light fa-trash-can",
        "delta":                           "fa-light fa-hexagon-exclamation",
        "department":                      "fa-light fa-building-user",
        "discount":                        "fa-light fa-badge-percent",
        "dislike":                         "fa-light fa-thumbs-down",
        "dispute":                         "fa-light fa-hexagon-exclamation",
        "dna":                             "fa-light fa-dna",     
        "docs":                            "fa-light fa-file-doc",
        "down":                            "fa-light fa-arrow-down",
        "down-to-line":                    "fa-light fa-down-to-line",        
        "download":                        "fa-light fa-download",
        "drag":                            "fa-light fa-bars",
        "dropdown-thin":                   "fa-light fa-chevron-down",
        "dropdown":                        "fa-light fa-chevron-down",
        "edit-rect":                       "fa-light fa-pen-square",
        "edit":                            "fa-light fa-pen-line",
        "email":                           "fa-light fa-envelope",    
        "email-check":                     "fa-light fa-envelope-circle-check",    
        "employee_tariff":                 "fa-light fa-hand-holding-dollar",
        "employee":                        "fa-light fa-square-user", 
        "enlarge":                         "fa-light fa-arrows-maximize",
        "error":                           "fa-light fa-triangle-exclamation",
        "error-filled":                    "fa-filled fa-triangle-exclamation",
        "example":                         "fa-light fa-file-image",
        "excel":                           "fa-light fa-file-excel",
        "exchange":                        "fa-light fa-arrows-repeat",
        "expand":                          "fa-light fa-square-plus",
        "export":                          "fa-light fa-file-excel",
        "file-format":                     "fa-light fa-file-heart",
        "files":                           "fa-light fa-files",
        "filter":                          "fa-light fa-filter",
        "flag-empty":                      "fa-light fa-flag",
        "flag":                            "fa-solid fa-flag",
        "flash-automation":                "fa-light fa-bolt-auto",
        "flash":                           "fa-light fa-bolt",
        "font-color":                      "fa-light fa-palette",
        "font-size-a":                     "fa-light fa-font-case",
        "font-size-t":                     "fa-light fa-font-case",
        "forward":                         "fa-light fa-arrow-turn-right",
        "heading":                         "fa-light fa-heading",
        "help":                            "fa-light fa-circle-question",
        "hide":                            "fa-light fa-eye-slash",
        "history":                         "fa-light fa-clock-rotate-left", 
        "hours":                           "fa-light fa-clock",
        "i18n":                            "fa-light fa-earth-europe",
        "iban":                            "fa-light fa-credit-card-front",
        "import":                          "fa-light fa-file-import",
        "indent-decrease":                 "fa-light fa-outdent",
        "indent-increase":                 "fa-light fa-indent",
        "invoice":                         "fa-light fa-file-lines",
        "italic":                          "fa-light fa-italic",
        "rt-italic":                       "fa-italic",                     // italic for richt text
        "jobtitle":                        "fa-light fa-users-viewfinder",
        "jpg":                             "fa-light fa-file-jpg",
        "like":                            "fa-light fa-thumbs-up",
        "link":                            "fa-light fa-link",
        "live-support":                    "fa-light fa-headset",
        "loader":                          "fa-light fa-spin fa-spinner-scale",
        "location":                        "fa-light fa-location-dot",
        "lock":                            "fa-light fa-lock",
        "log":                             "fa-light fa-notebook",
        "login":                           "fa-light fa-right-to-bracket",
        "logout":                          "fa-light fa-right-from-bracket",
        "mail":                            "fa-light fa-envelope",         
        "maintenance":                     "fa-light fa-person-digging",
        "manday":                          "fa-light fa-user-clock",
        "mandays":                         "fa-light fa-user-clock",
        "masterdata":                      "fa-light fa-layer-group",       
        "maximize":                        "fa-light fa-arrows-maximize",
        "menu-horizontal":                 "fa-light fa-ellipsis-h",
        "menu-vertical":                   "fa-light fa-ellipsis-vertical",
        "merge":                           "fa-light fa-merge",
        "minimize":                        "fa-light fa-light fa-arrows-minimize",
        "minus":                           "fa-light fa-minus",
        "mollie-incasso":                  "fa-light fa-money-check-dollar-pen", 
        "mollie-payment":                  "fa-light fa-display-chart-up-circle-dollar", 
        "moon":                            "fa-light fa-moon",
        "money":                           "fa-light fa-money-bill-wave",
        "moneybills":                      "fa-light fa-money-bills",
        "move":                            "fa-light fa-arrows-up-down-left-right",
        "new-product":                     "fa-light fa-cart-plus",
        "nospam":                          "fa-light fa-user-tie",
        "note":                            "fa-light fa-note-sticky",
        "noteempty":                       "fa-light fa-note-sticky",
        "notefilled":                      "fa-solid fa-note-sticky",
        "notification":                    "fa-light fa-bell",
        "notifications-off":               "fa-light fa-bell-slash",
        "open":                            "fa-light fa-pen-square",
        "options":                         "fa-light fa-sliders",
        "order":                           "fa-light fa-book-open",
        "ordered-list":                    "fa-light fa-list-ol",
        "orders":                          "fa-light fa-book-open",
        "package":                         "fa-light fa-box",
        "paid":                            "fa-light fa-file-plus",
        "paragraph":                       "fa-light fa-paragraph",
        "paraph":                          "fa-light fa-circle-check",
        "paraphs":                         "fa-light fa-circle-check", 
        "pause":                           "fa-light fa-pause",
        "payment":                         "fa-light fa-wallet", 
        "paymentorder":                    "fa-light fa-credit-card",
        "periodicinvoice":                 "fa-light fa-clock-desk", 
        "periodicinvoices":                "fa-light fa-clock-desk", 
        "pdf":                             "fa-light fa-file-pdf",
        "pdf-plus":                        "fa-light fa-file-circle-plus",
        "pen":                             "fa-light fa-pen",
        "person":                          "fa-light fa-person",
        "pie-chart":                       "fa-light fa-chart-pie-simple",    
        "chart-bar-h":                     "fa-light fa-chart-bar",   
        "chart-bar-v":                     "fa-light fa-chart-bar fa-rotate-90",   
        "phone":                           "fa-light fa-phone",
        "planning":                        "fa-light fa-bars-progress",
        "play":                            "fa-light fa-play",
        "plugin":                          "fa-light fa-puzzle-piece",
        "png":                             "fa-light fa-file-png",
        "print":                           "fa-light fa-print",
        "product":                         "fa-light fa-cart-shopping",
        "productgroup":                    "fa-light fa-cart-flatbed-boxes",
        "project":                         "fa-light fa-briefcase",
        "projectgroup":                    "fa-light fa-briefcase",
        "projectphase":                    "fa-light fa-briefcase",
        "projects":                        "fa-light fa-briefcase",
        "purchase":                        "fa-light fa-receipt",
        "purchaseinvoice":                 "fa-light fa-receipt",
        "qr-scan":                         "fa-light fa-qrcode",
        "rate-increase":                   "fa-light fa-chart-line-up",
        "rate":                            "fa-light fa-money-bill-wave",
        "recipe":                          "fa-light fa-cart-flatbed-boxes",
        "refresh":                         "fa-light fa-arrows-rotate",
        "reject":                          "fa-light fa-trash-can",
        "relation":                        "fa-light fa-user", 
        "relations":                       "fa-light fa-user", 
        "reminder":                        "fa-light fa-calendar-exclamation",
        "reminder2":                       "fa-light fa-calendar-exclamation",
        "remove":                          "fa-light fa-trash-can",
        "reports":                         "fa-light fa-file-chart-column",
        "rightsgroup":                     "fa-light fa-shield-check",
        "rotate-left":                     "fa-light fa-rotate-left",
        "rotate-right":                    "fa-light fa-rotate-right",
        "sad":                             "fa-light fa-face-frown",
        "salesinvoice":                    "fa-light fa-file-lines",
        "salesorder":                      "fa-light fa-book-open",
        "save":                            "fa-light fa-floppy-disk",
        "scan":                            "fa-light fa-barcode-scan",
        "search-big":                      "fa-light fa-magnifying-glass-waveform",
        "search":                          "fa-light fa-magnifying-glass",
        "security-alert":                  "fa-light fa-shield-exlamation",
        "security":                        "fa-light fa-shield",
        "select":                          "fa-light fa-hand-pointer",
        "select-from-list":                "fa-light fa-circle-plus",
        "send-basic":                      "fa-light fa-paper-plane",         
        "send":                            "fa-light fa-paper-plane-top",     
        "setting":                         "fa-light fa-gear",                   
        "settings":                        "fa-light fa-gear",                
        "show":                            "fa-light fa-eye",
        "shrink":                          "fa-light fa-arrows-minimize",
        "signal":                          "fa-light fa-signal",     
        "signature":                       "fa-light fa-file-signature",
        "smile":                           "fa-light fa-face-smile",
        "sort-down":                       "fa-light fa-arrow-down-short-wide",
        "sort-up-down":                    "fa-light fa-arrow-down-arrow-up",
        "sort-up":                         "fa-light fa-arrow-up-wide-short",
        "spam":                            "fa-light fa-user-secret",
        "split":                           "fa-light fa-split",
        "square-open":                     "fa-light fa-square-o",
        "square-check":                    "fa-light fa-check-square",
        "standardtext":                    "fa-light fa-message-text",
        "star":                            "fa-light fa-star",
        "stop":                            "fa-light fa-stop",
        "stopwatch-check":                 "fa-light fa-circle-check",
        "stopwatch-minus":                 "fa-light fa-circle-minus",
        "stopwatch-plus":                  "fa-light fa-circle-plus",
        "stopwatch-remove":                "fa-light fa-circle-xmark",
        "stopwatch":                       "fa-light fa-stopwatch",
        "striketrough":                    "fa-light fa-striketrough",
        "striketrough":                    "fa-light fa-striketrough",
        "subscription":                    "fa-light fa-money-bill-transfer",
        "sun":                             "fa-light fa-sun",
        "supplier":                        "fa-light fa-truck-field",
        "support":                         "fa-light fa-life-ring",
        "support-user":                    "fa-light fa-user-headset",
        "accountant":                      "fa-light fa-user-tie-hair",
        "tag":                             "fa-light fa-tag",
        "takeout":                         "fa-light fa-file-export",
        "tariff":                          "fa-light fa-coins",
        "tasks":                           "fa-light fa-comments-question-check",
        "tender-sign":                     "fa-light fa-file-signature",
        "tender":                          "fa-light fa-file-contract",
        "test":                            "fa-light fa-flask-gear",
        "text":                            "fa-light fa-text",
        "tip":                             "fa-light fa-circle-info",
        "transfer":                        "fa-light fa-arrow-right-arrow-left",
        "unarchive":                       "fa-light fa-inbox-out",
        "uncheck":                         "fa-light fa-square",        
        "underline":                       "fa-light fa-underline",
        "units":                           "fa-light fa-weight-hanging",
        "unit":                            "fa-light fa-weight-hanging",
        "unlink":                          "fa-light fa-link-slash",
        "unlock":                          "fa-light fa-unlock",
        "unpaid":                          "fa-light fa-file-minus",
        "up":                              "fa-light fa-arrow-up",
        "up-to-line":                      "fa-light fa-up-to-line",        
        "upload":                          "fa-light fa-upload",
        "user":                            "fa-light fa-circle-user",
        "vendor":                          "fa-light fa-shop",
        "warehouse":                       "fa-light fa-warehouse",
        "warning":                         "fa-light fa-triangle-exclamation",
        "warning-filled":                  "fa-filled fa-triangle-exclamation",
        "webhook":                         "fa-light fa-webhook",
        "widgets":                         "fa-light fa-grid",
        "width":                           "fa-light fa-text-width",
        "work":                            "fa-light fa-user-helmet-safety",
        "workorder":                       "fa-light fa-clipboard-user",
        "x":                               "fa-light fa-x",
        "xml":                             "fa-light fa-file-xml",
        "youtube":                         "fa-brands fa-youtube",
        "video":                           "fa-light fa-video",
        "zoom-in":                         "fa-light fa-magnifying-glass-plus",
        "zoom-out":                        "fa-light fa-magnifying-glass-minus",
        "zoomout":                         "fa-light fa-magnifying-glass-minus",

    };

    /**
     * Convert a type or specification or name to a concrete icon.
     */
    forType(str){
        if (!str) {
            str = "?";
        }
        return this.types[str] || null;
    }
};

export default clsIcon;