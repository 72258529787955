import { Mark } from '@tiptap/core';
import utils from './tiptap-utils';

// Allow for a custom element <w50> in the markup. 
// W50 stands for width 50%.
const Highlight = Mark.create({
    name: 'w50',
    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    parseHTML() {
        return [
            {
                tag: 'w50',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['w50', HTMLAttributes /* core.mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)*/, 0];
    },
    addCommands() {
        return {
            setW50: () => ({ editor, commands }) => {
                utils.selectWord(editor,commands);
                commands.unsetMark("w25")
                return commands.setMark(this.name)
            },
            toggleW50: () => ({ editor, commands }) => {
                console.log('toggleW50')
                utils.selectWord(editor,commands);
                commands.unsetMark("w25")
                return commands.toggleMark(this.name)
            },
            unsetW50: () => ({ editor, commands }) => {
                utils.selectWord(editor,commands);
                return commands.unsetMark(this.name)
            },
        }
    },
    addKeyboardShortcuts() {
        return {};
    },
    addInputRules() {
        return [
        ];
    },
    addPasteRules() {
        return [];
    },
});


export { Highlight, Highlight as default };